/* confirm modal */
.conf_Btn, .can_Btn {
    border: 2px solid #3f51b5 !important;
    color: rgb(0, 60, 126) !important;
    background-color: white !important;
    font-weight: 500;
     
  }
  .can_Btn:hover {
    background-color: #f44336 !important;
    color: white !important;
    border: 2px solid #f44336 !important; /* #B32624 */

  }
  .conf_Btn:hover {
      background-color: #2196f3 !important; /*#6AB324 */
    color: white !important;
    border: 2px solid #2196f3 !important;
  }