@import url("https://fonts.googleapis.com/css2?family=PT+Serif&family=Montserrat:wght@200;300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600");

.box {
  border-radius: 4px;
  height: auto;
  background-color: #3f51b5;
  color: white;
  font-family: "PT Serif", serif;
  border: 3px solid #3f51b5;
}

.backIcon {
  size: 35px;
  color: #3f51b5;
  background-color: white;
  border-radius: 2px;
  padding: 8px;
  margin-top: 10px;
  margin-right: 10px;
}

.tablefont {
  font-family: "PT Serif", serif;
  padding: 10px;
}

.tablefont1 {
  font-family: "Poppins";
  padding: 10px;
  font-weight: bold !important;
}

td {
  padding: 15px;
  margin: 10px;
}

.fixbox {
  width: auto;
}

.footerfont {
  font-family: "PT Serif", serif;
  color: #3f51b5;
}

.flex-container {
  display: flex;
  width: 100%;
}

.right {
  float: right;
  margin-right: 10px;
  text-align: right;
}

.flex-container > div {
  margin: 10px;
  padding: 20px;
  font-size: 30px;
  width: 100%;
}

hr.new1 {
  border-top: 1px solid white;
}

/*  */

.footerfont {
  background-color: #e5e7eb;
  border-radius: 10px;
}
.DtlsName {
  font-size: 18px;
  color: #3f51b5;
  margin-top: 10px;
}
.ViewPrice {
  color: #ff5c8e;
  font-weight: bold;
  font-size: 19px;
}
.ViewPriceFinal {
  color: blue;
  font-weight: bold;
  font-size: 20px;
}
.asign1 {
  color: #3f51b5;
  padding: -105px;
  position: absolute;
  margin-left: 45px;
  width: 42%;
}
.asign5 {
  color: #3f51b5;
  padding: -105px;
  position: absolute;
  margin-left: 127px;
  width: 42%;
}
.asign4 {
  color: #3f51b5;
  padding: -105px;
  position: absolute;
  margin-left: 131px;
  width: 42%;
}
.asign3 {
  color: #3f51b5;
  padding: -105px;
  position: absolute;
  margin-left: 62px;
  width: 42%;
}
.asign2 {
  color: #3f51b5;
  padding: -105px;
  position: absolute;
  margin-left: 18px;
  width: 42%;
}
.Assign_hedding1 {
  margin-left: 10px;
  margin-top: -30px;
  padding: 0px;
}
.finailAmoutnSign {
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
  color: red;
}
.EmplayeDetailsInput {
  width: 100%;
  border: solid;
  margin-top: 0px;
  display: -webkit-box;
  height: 48px;
  border: solid #3f51b5 1.5px;
  border-radius: 9px;
  padding: 11px;
  color: #3f51b5;
  margin-left: 75px;
}
.EmplayeInput {
  width: 100%;
  border: solid #3f51b5 1.5px;
  border-radius: 9px;
  padding: 11px;
  margin-top: 1px;
  display: flex;
  margin-left: 73px;
  color: #3f51b5;
}

.AssignBtn {
  margin-left: 528px;
  margin-top: -34px;
  display: flex;
}

#addBTN:hover {
  background-color: #03c9d7 !important;
  border: none;
}

.backButton {
  margin-left: -105px;
}

.EmplayBox {
  display: block;
  margin-top: 10px;
  margin-left: -156px;
}
#EmplyDitels {
  margin-top: 0px !important;
  margin-left: 0px !important;
  font-size: 27px !important;
  color: darkblue !important;
  display: flex;
}
.lablestyle,
.lablestyle1 {
  color: brown;
  font-size: 16px;
  margin-left: 77px;
}

#EmplyDitels {
  margin-top: -57px !important;
  margin-left: 0px !important;
  font-size: 27px !important;
  color: darkblue !important;
  display: flex;
}

.css-1m15cj8 {
  border-radius: 35px;
}
#EmplyDitels {
  margin-top: -57px !important;
  margin-left: -79px !important;
  font-size: 27px !important;
  color: darkblue !important;
  display: flex;
}

.AssignButton {
  background-color: #03c9d7;
  padding: 9px;
  color: #fff;
  border-radius: 7px;
  border: none;
  margin-top: 12px;
  margin-left: -138p;
  margin-left: 77px;
  width: 73px;
  display: flex;
}
.backButton1 {
  background-color: #ff5c8e;
  padding: 9px;
  color: #fff;
  border-radius: 7px;
  border: none;
  margin-top: -42px;
  margin-left: -138p;
  margin-left: 156px;
  width: 73px;
  display: flex;
}

/* ASSIGNED POP-UP And SHIP POP-UP */
.assigned_name {
  font-size: 26px;
  color: darkblue;
  text-align: center;
  margin-top: -1px;
}
.assignedOrderNo {
  color: brown;
  margin-left: 5px;
  margin-top: 15px;
  font-size: 19px;
}

.input {
  /* border: 2px solid; */
  margin-right: -4px;
  background-color: beige;
  width: 169px;
  margin-left: 5px;
  height: 31px;
  border-radius: 5px;
}

.assignedShip {
  background-color: #03c9d7;
  width: 72px;
  text-align: center;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  margin-top: 48px;
  /* display: flex; */
  position: absolute;
  margin-left: 127px;
}
.assignedcancel {
  background-color: #ff5c8e;
  width: 72px;
  text-align: center;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  float: left;
  margin-left: 209px;
  margin-top: 47px;
}

.BntList {
  margin-top: -18px;
  margin-left: -62px;
}
.tbody1 {
  color: #3f51b5;
  font-size: large;
}

.capsuleButton {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  background-color: #ff5c8e;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
