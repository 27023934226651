.container {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
}

.table-container {
  height: auto !important;
  max-height: 600px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  /* border: 2px solid rgba(0, 0, 0, 0.12) !important; */
  border-radius: 10px !important;
}
.table-container::-webkit-scrollbar {
  width: 2px;
}
.table-container::-webkit-scrollbar-track { 
  border-radius: 1px;
}
.table-container::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186); 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.newRecordBtn {
  color: rgb(0, 60, 126) !important;
  border-color: rgb(0, 60, 126) !important;
}
.newRecordBtn:hover {
  transition: all 0.3s ease-in-out !important;
  background-color: #003c7e !important;
  color: white !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.imgDrop {
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.addButton {
  background-color: #03c9d7;
  color: white;
  border: 2px solid #03c9d7;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.backButton {
  background-color: #ff5c8e;
  color: white;
  border: 2px solid #ff5c8e;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.editButton {
  background-color: #8be78b;
  color: white;
  border: 2px solid #8be78b;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.backIcon {
  size: 35px;
  color: white;
  background-color: #ff5c8e;
  border-radius: 2px;
  padding: 8px;
}

img {
  height: 80px;
  width: 80px;
  object-fit: contain;
  padding: 5px;
  border-radius: 15px;
  display: inline-block;
}

.block-icon {
  position: relative;
  display: inline-block;
}

.icon-tag {
  color: #ff5c8e;
  background-color: white;
  padding: 2px;
  font-weight: bolder;
  margin: 5px;
  size: 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 18px !important;
  height: 18px !important;
}

.icon-belowtag {
  color: #ff5c8e;
  background-color: white;
  padding: 2px;
  font-weight: bolder;
  margin: 5px;
  size: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 15px !important;
  height: 15px !important;
}

.modal {
  width: 450px;
  margin: auto;
}

/*dropdown Css */
.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
}
