.App {
  font-family: sans-serif;
  /* text-align: center; */
  background-color: #f6f9fe;
  height: 100vh;
}
.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
button {
  height: 40px;
}

.table-container {
  height: auto !important;
  max-height: 600px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
}
.table-container::-webkit-scrollbar {
  width: 2px;
}
.table-container::-webkit-scrollbar-track { 
  border-radius: 1px;
}
.table-container::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186); 
}
