@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

/* ::-webkit-scrollbar {
  width: 6px;
} */
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}


.e-grid .e-gridpager .e-prevpagedisabled,
.e-grid .e-gridpager .e-prevpage,
.e-grid .e-gridpager .e-nextpage,
.e-grid .e-gridpager .e-nextpagedisabled,
.e-grid .e-gridpager .e-lastpagedisabled,
.e-grid .e-gridpager .e-lastpage,
.e-grid .e-gridpager .e-firstpage,
.e-grid .e-gridpager .e-firstpagedisabled {
    background-color: #deecf9;
    color:black;
}



.e-grid .e-gridpager .e-numericitem {
 color: black;
}

.e-grid .e-gridpager .e-currentitem {
   
    color: white;
}




/* .e-grid .e-icon-ascending::before {
  content: '\e306';
}
.e-grid .e-icon-ascending::before {
  content: '\e304';
} */


/* .e-grid .e-sortnumber {
  background-color: #deecf9;
  font-family: cursive;
} */


/* .e-grid .e-filterbarcell {
  background-color: #deecf9;
} */



.e-grid .e-headercell {
  color: black !important;

}

.e-grid .e-toolbar-items {
  background-color: #deecf9 !important;
}
.e-grid .e-toolbar .e-btn {
  background-color: black !important ;
}
.e-grid td.e-selectionbackground {
  background-color: #deecf9 !important;
}
/* 
.e-grid .e-altrow {
  background-color: #f1f1f1d9 !important;
} */


.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after{
background:  #0096FF !important;
} 


.e-pager .e-currentitem{

  background:  #0096FF !important;
}