img {
  height: 160px;
  width: 160px;
  object-fit: contain;
  padding: 5px;
  border-radius: 15px;
  display: inline-block;
}

.container {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
}
.table-container {
  height: auto !important;
  max-height: 600px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
}
.table-container::-webkit-scrollbar {
  width: 2px;
}
.table-container::-webkit-scrollbar-track { 
  border-radius: 1px;
}
.table-container::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186); 
}
.myPage {
  color: #3f50b5;
}
.newRecordBtn {
  color: rgb(0, 60, 126) !important;
  border-color: rgb(0, 60, 126) !important;
}
.newRecordBtn:hover {
  transition: all 0.3s ease-in-out;
  background-color: #003c7e !important;
  color: white !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
